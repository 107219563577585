// Here you can add other styles
.authDiv {
  height: 100vh;
}

.cursor-pointer {
  cursor: pointer;
}

.login-div-box {
  background-repeat: no-repeat;
  background-size: 101% 101%;
  background-position: center;
  min-height: 350px;
}

.action-width {
  min-width: 170px;
}

.table-img {
  height: 50px;
}

.form-img {
  height: 200px;
}
